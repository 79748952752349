
import { defineComponent } from "vue";
import { IonText, IonCheckbox } from "@ionic/vue";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "MenuCard",

  components: { IonText, IonCheckbox },

  props: {
    menu: {
      type: Object,
      default: null,
      id_menu: null,
    },
  },

  computed: {
    ...mapGetters('order', {
      getMenus: 'getMenus'
    })
  },

  watch: {
    selected(val) {
      this.setItemMenu({
        id_menu: this.menu.id_menu,
        qty: this.portionCount,
        selected: val,
      });
      this.$emit('onUpdate')
    },
    portionCount(val) {
      this.setItemMenu({
        id_menu: this.menu.id_menu,
        qty: val,
        selected: this.selected,
      });
      this.$emit('onUpdate')
    },
  },

  data() {
    return {
      portionCount: 1,
      selected: false,
    };
  },

  methods: {
    ...mapMutations("order", {
      setItemMenu: "SET_ITEM_MENUS",
    }),
    minPortion() {
      if (this.portionCount > 1) {
        this.portionCount--;
      }
    },
  },
});
