
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonText,
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonButton,
  IonSelect,
  toastController,
  IonSelectOption,
} from "@ionic/vue";
import PageHeader from "@/components/partial/PageHeader.vue";
import MenuCard from "@/components/functional/order/MenuCard.vue";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "PkgDetail",

  components: {
    IonPage,
    IonContent,
    IonText,
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
    IonButton,
    PageHeader,
    MenuCard,
    IonSelect,
    IonSelectOption,
  },
  mounted() {
    this.getDetailPackage();
    this.getSchedule();
    this.getMonth();
  },
  data() {
    return {
      menu: {
        nama: "",
        gambar: "",
      },
      totalHarga: 0,
      scheduleList: [] as any,
      jamPengantaran: null,
      jadwalRadio: null,
      months: [] as any,
      month: "",
      portionCount: 1,
      loading_checkout: false,
      disabled_button: true,
    };
  },
  watch: {
    month() {
      this.getMenuMonth();
    },
    totalHarga(val) {
      if (val > 0) {
        this.disabled_button = false;
      } else {
        this.disabled_button = true;
      }
    },
    jadwalRadio(val) {
      const selected = this.scheduleList.filter((v) => {
        return v.idJadwal == val;
      })[0];
      this.jamPengantaran = selected.waktu;
    },
  },
  computed: {
    ...mapGetters("order", {
      menus: "getMenus",
    }),
  },
  methods: {
    updatePrice() {
      let harga = 0;
      this.menus.forEach((e: any) => {
        if (e.status >= 3 && e.qty > 0 && e.selected) {
          harga += parseInt(e.harga) * parseInt(e.qty);
        }
      });
      this.totalHarga = harga;
    },
    async checkout() {
      if (this.totalHarga <= 0) return;
      const formData = new FormData();
      this.menus.forEach((e: any) => {
        if (e.status >= 3 && e.qty > 0 && e.selected) {
          formData.append("id_menu[]", e.id_menu);
          formData.append("quantity[]", e.qty);
          formData.append("price[]", e.harga);
          formData.append("date[]", e.tgl_menu);
        }
      });
      this.jadwalRadio && formData.append("idJadwal", this.jadwalRadio);
      this.loading_checkout = true;
      axios
        .post("add-cart", formData)
        .then(async ({ data }) => {
          this.loading_checkout = false;
          const toast = await toastController.create({
            message: data.message,
            duration: 1500,
          });
          toast.present();
          this.$router.replace("/order/checkout/" + data.trx);
        })
        .catch(async ({ response }) => {
          this.loading_checkout = false;
          const toast = await toastController.create({
            message: response.data.message,
            duration: 1500,
          });
          toast.present();
        });
    },
    async getSchedule() {
      await axios
        .get("sub-schedule")
        .then(({ data }) => {
          this.scheduleList = data.data;
          this.configSchedule();
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2000,
          });
          toast.present();
        });
    },
    async getDetailPackage() {
      await axios
        .get("package-list")
        .then(({ data }) => {
          this.menu = data.data[0];
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2000,
          });
          toast.present();
        });
    },
    ...mapMutations("order", {
      setMenu: "SET_MENUS",
    }),
    async getMenuMonth() {
      const formdata = new FormData();
      this.month && formdata.append("month_id", this.month);

      await axios
        .post("menu-month", formdata)
        .then(({ data }) => {
          this.menus = data.data;
          this.setMenu(data.data);
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2000,
          });
          toast.present();
        });
    },
    async getMonth() {
      await axios.get("month-list").then(({ data }) => {
        this.months = data.data;
        this.month = this.months[0].month_id;
        this.getMenuMonth();
      });
    },
    configSchedule() {
      this.jadwalRadio = this.scheduleList[0].idJadwal;
      this.jamPengantaran = this.scheduleList[0].waktu;
    },
    minPortion() {
      if (this.portionCount > 1) {
        this.portionCount--;
      }
    },
  },
});
