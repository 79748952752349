
import { defineComponent } from 'vue'
import { IonHeader, IonToolbar, IonButtons, IonBackButton } from '@ionic/vue'

export default defineComponent ({
  name: 'PageHeader',

  components: { IonHeader, IonToolbar, IonButtons, IonBackButton },

  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    backLink: {
      type: String,
      default: null
    }
  }
})
