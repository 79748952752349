import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-semibold mb-2"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "font-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { color: "light" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["px-4 bg-cover bg-no-repeat flex items-center", [_ctx.backLink ? 'py-2' : 'py-2']])
          }, [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                (_ctx.backLink)
                  ? (_openBlock(), _createBlock(_component_ion_back_button, {
                      key: 0,
                      "default-href": _ctx.backLink
                    }, null, 8, ["default-href"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full", [!_ctx.title && !_ctx.subtitle && _ctx.text ? 'flex items-center' : '']])
            }, [
              (_ctx.title)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.title), 1))
                : _createCommentVNode("", true),
              (_ctx.subtitle)
                ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.subtitle), 1))
                : _createCommentVNode("", true),
              (_ctx.text)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.text), 1))
                : _createCommentVNode("", true)
            ], 2)
          ], 2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}